<template>
  <div class="min-h-screen hero ">
    <div class="text-center hero-content">
      <div class="max-w-md ">
        <div class=" flex items-center h-16 mb-5 space-x-3 ">
          <img
            src="@/assets/capacio/capacio-log-black.png"

            class="h-12 p-2 rounded-lg "
          > <h1
            class="text-2xl "
            v-if="selectedLang === 'EN'"
          >
            Questionnaire
          </h1>
          <h1
            class="text-3xl"
            v-else
          >
            Frågeformulär
          </h1>
        </div>

        <div v-if="$store.getters['QUESTIONNAIRE/getQuestionnaireList'][0] != 'REQ_SPEC'">
          <h5
            class="leading-8"
            v-if="selectedLang === 'EN'"
          >
            Select questionnaire language
          </h5>
          <h5
            v-else
            class="leading-8 "
          >
            Välj språk på frågeformuläret
          </h5>
          <div class="flex justify-center my-8 space-x-6 items-center">
            <div>
              <img
                src="@/assets/flags/se.svg"
                width="40px"
                height="40px"
              >
              <input
                v-model="selectedLang"
                type="radio"
                class="mt-1 radio"
                value="SV"
              >
            </div>
            <div>
              <img
                src="@/assets/flags/gb.svg"
                width="40px"
                height="40px"
              >
              <input
                v-model="selectedLang"
                type="radio"
                class="mt-1 radio"
                value="EN"
              >
            </div>
          </div>
        </div>
        <p
          v-if="selectedLang === 'EN'"
          class="my-5"
        >
          Enter email
        </p>
        <p
          v-else
          class="my-5"
        >
          Fyll i din epostadress
        </p>
        <div
          class="form-control"
          style="width: 385px;"
        >
          <div class="relative">
            <input
              v-model="email"
              type="email"
              placeholder="Email"
              class="w-full pr-16 input input-primary input-bordered text-sm start-input"
              :class="{'input-error': invalidEmail }"
            >
            <button
              class="absolute top-0 right-0 btn btn-primary-small email-button"
              @click="begin"
            >
              <span v-if="selectedLang === 'EN'">START</span>
              <span v-else>Starta</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      email: '',
      invalidEmail: false,
      selectedLang: 'EN'
    }
  },
  methods: {
    async begin () {
      this.invalidEmail = false
      if (this.email === '') {
        this.invalidEmail = true
        return
      }
      const res = await this.$store.dispatch('QUESTIONNAIRE/createUser', this.email.toLowerCase())
      if (res === 'invalidEmail') {
        this.invalidEmail = true
        return
      }
      this.$store.commit('QUESTIONNAIRE/SET_QUESTIONNAIRE_LANG', this.selectedLang)
      this.$router.push(`/questionnaire/${this.$store.getters['QUESTIONNAIRE/getQuestionnaireList'][0]}`)
    }
  }
}
</script>

<style>
.email-button{
  min-height: 4.3rem !important;
  border-bottom-right-radius: 0.5rem ;
  border-top-right-radius: 0.5rem ;
  border-top-left-radius: 0px !important ;
  border-bottom-left-radius: 0px !important ;
}
.start-input{
  padding-top: 1.7em !important;
  padding-bottom: 1.7rem !important;
}
@media screen and (max-width : 480px) {
.start-input{
width: 280px;
}
.form-control{
  display: flex;
  flex-direction: row;
}
}

</style>
